import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

export const drawerItem = [
    {
        title: 'Dashboard',
        navigate: '/dashboard',
        icon: <DashboardIcon />
    },
    {
        title: 'Business',
        navigate: '/business',
        icon: <AccountBalanceIcon />
    },
    {
        title: 'All Compliances',
        navigate: 'compliance',
        icon: <ImportContactsIcon />
    },
    {
        title: 'My Compliances',
        navigate: 'compliance/my',
        icon: <ContentPasteSearchIcon />
    },
    {
        title: 'Compliance Calendar',
        navigate: 'compliance/calendar',
        icon: <CalendarMonthIcon />
    },
];

export const DrawerList = ({list, navigate}) => {
    
    return(
        <React.Fragment>
            {list.map((item, index) => (
                <ListItemButton key={item.navigate} onClick={() => navigate(item.navigate)}>
                    <ListItemIcon>
                        {item.icon}
                    </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
            ))}    
        </React.Fragment>
    );
}
