import React from "react";
import { Button, Grid, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

type Props = {
    title: string;
    onAddClick?: any;
    children?: any;
};

const MasterHeader = ({ title, onAddClick, children }: Props) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
        >
        <Grid item>
            <Typography variant="h5">{title}</Typography>
        </Grid>

        {onAddClick ? (
            <Grid item>
                <Button
                    onClick={onAddClick}
                    startIcon={<AddIcon />}
                    variant="contained"
                >
                    Add
                </Button>
            </Grid>
        ) : null}

        {children ? (
            <Grid item>
                {children}
            </Grid>
        ) : null}


    </Grid>
  );
};

export default MasterHeader;
